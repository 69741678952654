<template>
    <div class="switch">
        <div class="sum">
            共计{{total}}个，当前页面呈现{{pageSize}}个
        </div>
        <div class="switch_box">
            <div 
                :class="['item',item.value == curSwitch ? 'active':'']" 
                v-for="item in switchList" 
                :key="item.value"
                @click="changeType(item.value)"
            >
                <svg-icon class="icon" :icon-class="item.icon"></svg-icon>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['total','pageSize','curSwitch'],
    data(){
        return{
            switchList:[
                {
                    name:'cube',
                    value:0,
                    icon:'icon_cube'
                },
                {
                    name:'list',
                    value:1,
                    icon:'icon_list'
                }
            ],
        }
    },
    methods:{
        changeType(val){
            // this.curSwitch = val
            this.$emit('change',val)
        }
    }
}
</script>
<style scoped lang="less">
.switch{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .sum{
        font-size: 18px;
        color: rgb(136, 136, 136);
    }
    .switch_box{
        display: flex;
        .item{
            width: 36px;
            height: 36px;
            background-color: #f5f5f5;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            color: #bbb;
            cursor: pointer;
            transition: .3s;
            .icon{
                font-size: 26px;
                color: #bbb;
            }
            &:hover,
            &.active{
                
                background-color: #e5e5e5;
                .icon{
                    color: #888;
                }
            }
        }
    }
}
</style>