<template>
    <div class="item">
        <!-- <div class="tag">精选</div> -->
        <div class="pic" @click="goDetail">
            <img :src="data.ImageUrl" alt="" />
        </div>
        <div class="txt">
            <h2>
                <pre class="dot2" @click="goDetail">{{ data.Name }}</pre>
                <a-tooltip placement="top">
                    <template slot="title">
                        <span v-if="data.IsCollection">取消收藏</span>
                        <span v-else>收藏</span>
                    </template>
                    <i
                        :class="[data.IsCollection ? 'active' : '']"
                        @click="toggleCollect"
                    >
                        <svg-icon
                            icon-class="icon_collect"
                            class="icon_collect"
                        ></svg-icon>
                    </i>
                </a-tooltip>
            </h2>
            <div class="des">
                <pre class="dot3">{{ data.Info }}</pre>
            </div>
            <div class="time">{{ time }}</div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { ToggleCollectModel } from '@/api/collection'
export default {
    props: ['data'],
    data() {
        return {}
    },
    computed: {
        time() {
            return this.data.CreateTime.split(' ')[0].split('/').join('-')
        },
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    methods: {
        goDetail() {
            this.$router.push({
                name: 'learning-info',
                params: { id: this.data.Id },
            })
        },
        toggleCollect() {
            const user = this.userInfo.LoginName
            if (!user) {
                this.$message.info('登录之后才能收藏，请先登录！')
                this.$store.commit('saveLoginDialog', true)
            } else {
                ToggleCollectModel(this.data.Id)
                    .then(res => {
                        const msg = this.data.IsCollection
                            ? '取消成功'
                            : '收藏成功'
                        this.$message.success(msg)
                        this.data.IsCollection = !this.data.IsCollection
                    })
                    .catch(e => e)
            }
        },
    },
}
</script>
<style scoped lang="less">
.item {
    background-color: #f5f5f5;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    position: relative;
    transition: 0.3s;
    &:hover {
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
            0 9px 28px 8px #0000000d;
        h2 pre {
            color: #f49133;
        }
    }
    &:not(:nth-of-type(3n)) {
        margin-right: 30px;
    }
    .tag {
        position: absolute;
        left: 0;
        top: 0;
        line-height: 30px;
        padding: 0 10px;
        background-color: #f49133;
        color: #fff;
        border-radius: 0 0 13px 0;
    }
    .pic {
        margin-right: 30px;
        flex-shrink: 0;
        cursor: pointer;
        img {
            width: 120px;
            height: 180px;
            background-color: #eee;
            object-fit: cover;
        }
    }
    .txt {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    h2 {
        display: flex;
        cursor: pointer;
        pre {
            font-size: 17px;
            // font-weight: bold;
            color: #333;
            line-height: 1.566;
            flex: 1;
        }
        i {
            flex-shrink: 0;
            color: #999;
            transition: 0.3s;
            margin-left: 10px;
            .icon_collect {
                font-size: 20px;
            }
            &:hover,
            &.active {
                color: #f49133;
            }
        }
    }
    .des {
        font-size: 16px;
        color: rgb(85, 85, 85);
        line-height: 1.5;
        min-height: 1.5 * 3em;
        padding-right: 20px;
        cursor: pointer;
    }
    .time {
        font-size: 16px;
        color: rgb(136, 136, 136);
    }
}
</style>
