<template>
    <div class="learning-list">
        <BgTop>
            <Nav @change="subjectChange" />
        </BgTop>
        <div class="main">
            <div class="auto-1440">
                <div class="search-wrap">
                    <Search @search="onSearch" v-model="params" />
                    <SwitchList @change="switchList" :total="total" :pageSize="list.length" :curSwitch="switchtype" />
                </div>

                <div class="list clearfix">
                    <a-list :grid="grid[switchtype]" :data-source="list" :loading="loading">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <CubeItem :data="item" v-if="switchtype == 0" />
                            <ListItem :data="item" v-else />
                        </a-list-item>
                    </a-list>
                </div>
                <Pagination :pageSize="params.pageSize" :total="total" @pageChange="pageChange"
                    :current="params.pageIndex" />
            </div>
        </div>
    </div>
</template>
<script>
import BgTop from '@/components/common/BgTop'
import Pagination from '@/components/common/Pagination'
import Nav from '@/components/Learning/List/Nav'
import Search from '@/components/Learning/List/Search'
import SwitchList from '@/components/Learning/List/SwitchList'
import CubeItem from '@/components/Learning/List/CubeItem'
import ListItem from '@/components/Learning/List/ListItem'
import { mapState } from 'vuex'
import { GetList, GetListSenior } from '@/api/model'
export default {
    metaInfo() {
        return {
            title: this.mateInfo?.Title || '文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: this.mateInfo?.Title || '文物出版社',
                },
                {
                    name: 'description',
                    content: this.mateInfo?.Title || '文物出版社',
                },
            ],
        }
    },
    components: {
        BgTop,
        Nav,
        Search,
        SwitchList,
        Pagination,
        CubeItem,
        ListItem,
    },
    computed:{
        ...mapState({
            navList: state => state.app.navList,
        }),
        mateInfo(){
            return this.navList.find(i => i.LinkUrl.indexOf('#/learning/list') !== -1)
        }
    },
    data() {
        return {
            list: [],
            params: {
                pageIndex: 1,
                pageSize: 12,
                subject: '',
            },
            total: 0,
            loading: false,
            grid: [
                { gutter: 30, column: 3 },
                { gutter: 0, column: 1 },
            ],
            switchtype: 0,
        }
    },
    methods: {
        // 切换列表形式
        switchList(val) {
            this.switchtype = val
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 200)
        },
        // 获取列表
        async getList() {
            console.log(this.params)
            this.loading = true
            await GetListSenior(this.params)
                .then(res => {
                    const { data, total } = res
                    data.forEach(item => {
                        item.key = item.Id
                    })
                    this.list = data
                    this.total = total
                })
                .catch(e => e)
            this.loading = false
        },
        // 翻页
        pageChange(page) {
            console.log(page)
            this.params.pageIndex = page.page
            document.documentElement.scrollTop = 0
            this.getList()
        },
        // 翻页
        onSearch(obj) {
            this.params.pageIndex = 1
            this.getList()
        },
        subjectChange(ids) {
            this.params.subject = ids.join(',')
            this.params.pageIndex = 1
            this.getList()
        },
    },
    created() {
        // this.getList()
    },
}
</script>
<style lang="less" scoped>
.main {
    padding: 49px 0 80px;
}

// .list{
//     display: flex;
//     flex-wrap: wrap;
//     margin-bottom: 30px;
//     /deep/.item{
//         width: calc(33.33% - 20px);
//     }
//     &.line{
//         /deep/.item{
//             width: 100%;
//             margin-right: 0;
//         }
//     }
// }
/deep/.ant-list {
    min-height: 100px;
}

.search-wrap {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
}
</style>
