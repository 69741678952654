<template>
    <div class="filter">
        <a-skeleton active :loading="loading">
            <div class="cate">
                <div
                    class="item"
                    key="all"
                    :class="curSubject == 'all' ? 'active' : ''"
                    @click="changeSubject('all')"
                >
                    全部
                </div>
                <div
                    class="item"
                    :id="item.Id"
                    :class="item.Id == curSubject ? 'active' : ''"
                    v-for="item in subjectList"
                    :key="item.Id"
                    @click="changeSubject(item)"
                >
                    <span>{{ item.Title }}</span>
                </div>
            </div>
            <div class="subCate">
                <template v-for="item in subjectList">
                    <div v-if="item.Id == curSubject" :key="item.Id">
                        <div
                            class="item"
                            v-for="subItem in item.children"
                            :key="subItem.Id"
                        >
                            <div class="label">
                                <span class="tag">{{ subItem.Title }}</span>
                            </div>
                            <div class="subCate_inner">
                                <div
                                    class="subCate_item"
                                    @click="changeSubSubject(subItem)"
                                    :class="
                                        subItem.Id == curSubSubject
                                            ? 'active'
                                            : ''
                                    "
                                >
                                    <span class="tag">全部</span>
                                </div>
                                <div
                                    class="subCate_item"
                                    v-for="subsubitem in subItem.children"
                                    :key="subsubitem.Id"
                                    :id="subsubitem.Id"
                                    :class="
                                        subsubitem.Id == curSubSubject
                                            ? 'active'
                                            : ''
                                    "
                                    @click="changeSubSubject(subsubitem)"
                                >
                                    <span class="tag">
                                        {{ subsubitem.Title }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </a-skeleton>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    data() {
        return {
            subjectList: [],
            curSubject: '',
            curSubSubject: '',
            loading: false,
        }
    },
    computed: {
        ...mapState({
            subjectListNoTree: state => state.model.subjectListNoTree,
        }),
    },
    methods: {
        // 获取考试科目
        async getSubjectList() {
            this.loading = true
            this.subjectList = await this.$store.dispatch('model/getSubject')
            // 获取导航科目id
            const id = this.$route.query.subject
            // 获取各级选中id
            if (id) {
                this.curSubject = id
                this.curSubject = this.findId(id).curSubject
                this.curSubSubject = this.findId(id).curSubSubject
                const curSubjectItem = this.findId(id).curItem
                const ids = this.createIds(curSubjectItem)
                // 触发父级事件
                this.$emit('change', ids)
            } else {
                this.curSubject = 'all'
                this.$emit('change', [])
            }

            this.loading = false
        },
        // 切换一级考试科目
        changeSubject(item) {
            this.curSubSubject = ''
            this.curSubject = item?.Id || 'all'
            if (item == 'all') {
                const ids = []
                this.$emit('change', ids)
            } else {
                this.curSubject = item?.Id || 'all'
                const ids = this.createIds(item)
                this.$emit('change', ids)
            }
        },
        // 切换二级考试科目
        changeSubSubject(item) {
            this.curSubSubject = item.Id
            const ids = this.createIds(item)
            this.$emit('change', ids)
        },
        // 获取选中ids
        createIds(parent) {
            if (!parent.children) {
                return [parent.Id]
            } else {
                let ids = []
                ids.push(parent.Id)
                parent.children.forEach(ele => {
                    ids.push(ele.Id)
                    if (ele.children) {
                        ids = ids.concat(ele.children.map(i => i.Id))
                    }
                })
                return ids
            }
        },
        // 根据id查找选中id以及选中父级id
        findId(id) {
            let curItem, curSubject, curSubSubject

            if (id) {
                this.subjectListNoTree.forEach(item => {
                    if (item.Id == id) {
                        curItem = item
                        curSubSubject = item.Id
                        curSubject = this.subjectListNoTree.find(
                            ele => ele.Id == item.ParentId
                        ).ParentId
                    }
                })
            } else {
                curItem = this.subjectList[0]
                curSubject = this.subjectList[0].Id
                curSubSubject = ''
            }
            return {
                curItem,
                curSubject,
                curSubSubject,
            }
        },
    },
    created() {
        this.getSubjectList()
    },
}
</script>
<style scoped lang="less">
@import '~@/assets/css/TopList.less';
</style>
