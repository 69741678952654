<template>
    <div class="top">
        <div class="auto-1440">
            <Breadcrumb :title="title" type="grey" />
            <slot></slot>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/common/Breadcrumb'
export default {
    props: ['title'],
    components: {
        Breadcrumb,
    },
}
</script>
<style scoped lang="less">
.top {
    // background: url('~@/assets/images/learning_nav_bg.jpg') center bottom / cover no-repeat;
    // padding-bottom: 40px;
}
</style>
